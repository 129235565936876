import axios from './AxiosHelper';

// define public methods
export const userService = {    
    getSelf,
    post,
    updatePassword,
    getProperties,
    postProperties
};

function getSelf() {
    return axios.getData(`/api/user/me`, true);
}

function post(data) {
    return axios.upsertData(data.id, `/api/user`, data, true)
}

function updatePassword(values) {    
    return axios.putData(`/api/user/${values.id}/password`, values, true);
}

function getProperties(id) {
    return axios.getData(`/api/user/${id}/properties`, true);
}

function postProperties(id, values) {    
    return axios.postData(`/api/user/${id}/properties`, values, true);   
}