import React from "react";
import { Link } from "gatsby";
import { Modal, Button } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";

export default ({ visible, item, onCancel }) => {
    return (
        <Modal
            open={visible}
            title={
                <strong>
                    <CheckCircleTwoTone twoToneColor="#52c41a" className="mr-2" />
                    Added to Cart
                </strong>
            }
            onCancel={() => onCancel && onCancel()}
            footer={[
                <Button key="submit" className="btn btn-primary" onClick={onCancel}>
                    Close
                </Button>,
                <Link key="cart" to="/cart" className="btn btn-primary ml-2" onClick={onCancel}>
                    Checkout
                </Link>,
            ]}
        >
            <p>An item has been added to your cart</p>

            {item && <div>{item.title}</div>}
        </Modal>
    );
};
