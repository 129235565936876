module.exports = [{
      plugin: require('../node_modules/gatsby-source-storm/gatsby-browser.js'),
      options: {"plugins":[],"host":"storm-tzk-api.azurewebsites.net","appkey":"C33EFB28-B5A5-4DC5-90CD-59E3F7736825"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TzkSeminars","short_name":"tzk","start_url":"/","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e409cecd7ccafc32ba66ce73598f6462"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-P665MKW","AW-1005138623","G-E6KYJH3X07","GTM-5R5WTLB"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{"allow_enhanced_conversions":true,"anonymize_ip":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
