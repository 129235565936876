import { encode, decode } from "js-base64";

const isBrowser = typeof window !== `undefined`;

const getUser = () => (window.localStorage.gatsbyUser ? JSON.parse(decode(window.localStorage.gatsbyUser)) : {});

export const setUser = (user) => (window.localStorage.gatsbyUser = encode(JSON.stringify(user)));

export const isLoggedIn = () => {
    if (!isBrowser) return false;

    const user = getUser();

    return !!user.email;
};

export const getCurrentUser = () => isBrowser && getUser();

export const getUserProperty = (prop) => {
    if (!isBrowser) return null;
    const user = getUser();

    const p = user?.properties?.find((o) => o.slug.toLowerCase() === prop.toLowerCase());
    return p;
};

export const getUserPropertyValue = (prop) => {
    const p = getUserProperty(prop);
    return p?.value || "";
};

export const updateUserProperties = (props) => {
    if (props === null) return;
    const user = getUser();
    user.properties = props;
    setUser(user);
};

export const logout = (callback) => {
    if (!isBrowser) return;

    setUser({});
    if (callback) callback();
};
