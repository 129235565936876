import axios from "./AxiosHelper";

// define public methods
export const ecommerceService = {
    getCart,
    getCheckout,
    getOrders,
    getOrder,
    getPromoCode,
    getIndividualPromoCodes,
    postOrder,
    getSubmission,
};

function getCart(slugs) {
    return axios.postData(`/api/ecommerce/cart`, slugs, false);
}

function getCheckout(slugs) {
    return axios.postData(`/api/ecommerce/checkout`, slugs, true);
}

function getOrders() {
    return axios.getData(`/api/ecommerce/order/me`, true);
}

function getOrder(id) {
    return axios.getData(`/api/ecommerce/order/${id}`, true);
}

function getPromoCode(code, contactid) {
    return axios.getData(`/api/ecommerce/promocode/${contactid}/${code}`, true);
}

function getIndividualPromoCodes() {
    return axios.getData(`/api/ecommerce/promocode/individual`, true);
}

function postOrder(data) {
    return axios.upsertData(data.id, `/api/ecommerce/order`, data, true);
}

function getSubmission(formid, subid) {
    return axios.getData(`/api/form/${formid}/submissions/${subid}`, true);
}
