import React, { useState, useEffect } from "react";
import { notify } from "utils/Notify";
import { userService } from "services/UserService";
import { ecommerceService } from "services/EcommerceService";
import { updateUserProperties } from "utils/auth";
import CartModal from "components/layouts/cartmodal";

export const siteContext = React.createContext();

const SiteProvider = (props) => {
    const [cart, setCart] = useState([]);
    const [user, setUser] = useState({});
    const [properties, setProperties] = useState([]);
    const [orderData, setOrders] = useState({});
    const [loading, setLoading] = useState(false);
    const [addToCartModalVisible, setAddToCartModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState();

    //#region Cart
    useEffect(() => {
        const c = JSON.parse(localStorage.getItem("cart")) || [];
        // console.log("ctx cart", c);
        setCart(c);
    }, []);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [JSON.stringify(cart)]);

    const addToCart = (item) => {
        let c = [...cart];
        if (!c.includes(item.slug)) {
            c.push(item.slug);
            setCart(c);
            setSelectedItem(item);
            setAddToCartModalVisible(true);
        }
    };

    const removeFromCart = (item) => {
        let c = [...cart];
        const idx = c.indexOf(item);
        if (idx > -1) {
            c.splice(idx, 1);
            setCart(c);
            notify.success("The item has been removed from your cart.");
        }
    };

    const emptyCart = () => {
        localStorage.removeItem("cart");
        setCart([]);
    };

    const isInCart = (item) => {
        let c = [...cart];
        return c.indexOf(item) > -1;
    };
    //#endregion

    //#region User Profile
    const loadUser = (callback) => {
        setLoading(true);

        userService
            .getSelf()
            .then((data) => {
                setUser(data);
                if (callback) callback();
            })
            .catch((err) => notify.error("Can't load the user"))
            .finally(() => setLoading(false));
    };

    const updateUserProfile = (values) => {
        setLoading(true);

        values.id = user.id;

        userService
            .post(values)
            .then((data) => {
                notify.success("Your profile has been updated");

                if (data.id !== undefined) setUser(data);
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const updateUserPassword = (values) => {
        setLoading(true);

        values.id = user.id;

        userService
            .updatePassword(values)
            .then((data) => notify.success("You have successfully updated the password"))
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const loadProperties = () => {
        setLoading(true);

        if (!user?.id) return;

        userService
            .getProperties(user.id)
            .then((data) => setProperties(data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const saveProperties = (values) => {
        setLoading(true);

        userService
            .postProperties(user.id, values)
            .then((data) => {
                // now reget all the props and update the user in local storage
                userService
                    .getProperties(user.id)
                    .then((data) => {
                        setProperties(data); // updates this state
                        updateUserProperties(data); // updates the local storage
                    })
                    .catch((err) => console.log(err));

                notify.success("The properties have been updated");
            })
            .catch((err) => notify.error(err))
            .finally(() => setLoading(false));
    };

    const loadOrders = () => {
        setLoading(true);

        ecommerceService
            .getOrders(user.id)
            .then((data) => setOrders(data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };
    //#endregion

    return (
        <siteContext.Provider
            value={{
                cart,
                addToCart,
                removeFromCart,
                emptyCart,
                isInCart,

                user,
                properties,
                orderData,
                loading,

                loadUser,
                updateUserProfile,
                updateUserPassword,
                loadProperties,
                saveProperties,
                loadOrders,
                setLoading,
            }}
        >
            {props.children}
            <CartModal visible={addToCartModalVisible} item={selectedItem} onCancel={() => setAddToCartModalVisible(false)} />
        </siteContext.Provider>
    );
};

// whatever uses this will be wrapped with the Provider
export default ({ element }) => <SiteProvider>{element}</SiteProvider>;
